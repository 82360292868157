import editDictionary from "../../views/productDictionary/EditDictionary.vue";

export const parseState = [
  { label: '解析失败', value: 0 },
  { label: '解析成功', value: 1 },
]

export const columns = [
  {
    title: '审批模板',
    ellipsis: true,
    width: 120,
    dataIndex: 'template_name',
    customRender: text => text || '-'
  },
  {
    title: '模板类型',
    ellipsis: true,
    dataIndex: 'approval_type_name',
    customRender: text => text || '-'
  },
  {
    title: '生成出库单节点',
    ellipsis: true,
    dataIndex: 'outnode',
    customRender: text => text || '-'
  },
  {
    title: '标题名称',
    ellipsis: true,
    dataIndex: 'parent_name',
    customRender: text => text || '-'
  },
  {
    title: '选项名称',
    ellipsis: true,
    dataIndex: 'title',
    customRender: text => text || '-'
  },
  {
    title: '对应类型',
    width: 100,
    ellipsis: true,
    dataIndex: 'to_type_name',
    customRender: text => text ?? '-'
  },
  {
    title: '对应产品',
    ellipsis: true,
    dataIndex: 'type_value',
    customRender: text => text?.name || '-'
  },
  {
    title: '解析状态',
    dataIndex: 'is_config',
    scopedSlots: { customRender: 'is_config' }
  },
  {
    title: '更新时间',
    dataIndex: 'update_time',
    customRender: text => text || '-'
  },
  {
    title: '操作',
    width: '120px',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

// export const columnsInner = [
//   {
//     title: '标题名称',
//     ellipsis: true,
//     dataIndex: 'parent_name',
//     customRender: text => text || '-'
//   },
//   {
//     title: '选项名称',
//     ellipsis: true,
//     dataIndex: 'title',
//     customRender: text => text || '-'
//   },
//   {
//     title: '对应类型',
//     width: 100,
//     ellipsis: true,
//     dataIndex: 'to_type_name',
//     customRender: text => text ?? '-'
//   },
//   {
//     title: '对应产品',
//     ellipsis: true,
//     dataIndex: 'type_value',
//     customRender: text => text.name || '-'
//   },
//   {
//     title: '解析状态',
//     width: 120,
//     dataIndex: 'to_type',
//     scopedSlots: { customRender: 'to_type' }
//   },
//   {
//     title: '更新时间',
//     dataIndex: 'update_time',
//     customRender: text => text || '-'
//   },
//   {
//     title: '操作',
//     width: '120px',
//     dataIndex: 'operation',
//     scopedSlots: { customRender: 'operation' }
//   }
// ]

export const tempRules = {
  approvaltype: [{ required: true, message: '请选择模板类型', trigger: 'change' }],
  node: [{ required: true, message: '请选择模板节点', trigger: 'change' }],
}

export const prodChooseColumns = [
  {
    title: '序号',
    align: 'center',
    dataIndex: 'index',
    customRender: (value, item, index) => index + 1,
  },
  {
    title: '产品编号',
    dataIndex: 'id',
    customRender: text => text ?? '-'
  },
  {
    title: '产品名称',
    dataIndex: 'goods_name',
    customRender: text => text ?? '-'
  },
  {
    title: '规格',
    dataIndex: 'goods',
    customRender: text => text ?? '-'
  },
  {
    title: "单位",
    dataIndex: "status",
    customRender: text => text ?? '-'
  },
  {
    title: "库存数量",
    dataIndex: "num",
    customRender: text => text ?? '-'
  },
]

export const dictionaryRules = {
  node: [{ required: true, message: '请选择对应类型', trigger: 'change' }],
  production: [{ required: true, message: '请选择对应产品', trigger: 'change' }],
}
