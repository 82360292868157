<template>
  <a-modal
    :width="800"
    centered
    destroyOnClose
    :title="formModal.title"
    :visible="formModal.open"
    :confirm-loading="loading"
    @ok="onConfirm"
    @cancel="closeModal"
  >
    <a-spin :spinning="loading">
      <a-form-model
          ref="formRef"
          :model="form"
          :rules="rules"
          :label-col="{ span: 3 }"
          :wrapper-col="{ span: 16 }"
      >
        <a-form-model-item label="审批模板">
          <span>{{ dataSource.name || '-' }}</span>
        </a-form-model-item>
        <a-row>
          <a-col span="12">
            <a-form-model-item
                :label-col="{ span: 6 }"
                :wrapper-col="{ span: 18 }"
                label="标题名称">
              <span>{{ dataSource.parent_name || '-' }}</span>
            </a-form-model-item>
          </a-col>
          <a-col span="12">
            <a-form-model-item
                :label-col="{ span: 6 }"
                :wrapper-col="{ span: 18 }"
                label="选项名称">
              <span>{{ dataSource.title || '-' }}</span>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-form-model-item prop="name" label="对应类型">
          <a-radio-group :options="getOption" :value="checked" @change="onChange" />
        </a-form-model-item>
        <a-form-model-item prop="production" label="对应产品" :wrapper-col="{ span: 21 }" v-if="![3, 6, 0].includes(checked)">
          <div class="combo-grid" v-if="checked === 1">
            <a-button type="primary" icon="plus" @click="openProdModal(false)" :style="{ marginRight: '10px' }">增加产品</a-button>
            <div v-for="(item, index) in currentSource" :key="index" class="combo-grid-item">
              <span>{{ item.goods_name }}</span>
              <a-divider type="vertical" />
            </div>
          </div>
          <div class="combo-list" v-if="checked === 2">
            <a-button  type="primary" icon="plus" @click="$refs.comboChoose.openModal(currentSource)">选择套餐</a-button>
            <span :style="{ marginLeft: '10px' }">{{ getCurrentCombo }}</span>
          </div>
          <div class="combo-list" v-if="checked === 4">
            <a-radio-group v-model="form.amount">
              <template v-if="dataSource.approvaltype.approval_money">
                <template v-for="itm in dataSource.approvaltype.approval_money">
                  <a-radio :value="itm.id" :key="itm.id">{{ itm.name }}</a-radio>
                </template>
              </template>
              <span v-else>-</span>
            </a-radio-group>
          </div>
          <div class="combo-grid" v-if="checked === 5">
            <a-button type="primary" icon="plus" @click="openProdModal(false)" :style="{ marginRight: '10px' }">选择原产品</a-button>
            <div v-for="(item, index) in currentSource" :key="index" class="combo-grid-item">
              <span>{{ item.goods_name }}</span>
              <a-divider type="vertical" />
            </div>
          </div>
        </a-form-model-item>
        <div class="combo-grid" v-if="checked === 5" :style="{ marginLeft: '93px' }">
          <a-button type="primary" icon="plus" @click="openProdModal(true)" :style="{ marginRight: '10px' }">选择定制品</a-button>
          <div v-for="(item, index) in customSource" :key="index" class="combo-grid-item">
            <span>{{ item.goods_name }}</span>
            <a-divider type="vertical" />
          </div>
        </div>
      </a-form-model>
    </a-spin>
    <material-select-modal
        ref="material"
        v-model="materialsSelectModalVisible"
        :warehouse="form.warehouse"
        @select="getProductions"
    />
    <comboChoose ref="comboChoose" @ok="getCombos" />
  </a-modal>
</template>

<script>

import { dictionaryDetail, updateDictionary } from '@/api/productionDictionary';
import { message } from "ant-design-vue"

export default {
  name: "EditDictionary",
  components: {
    MaterialSelectModal: () => import("@/components/MaterialSelectModal/index"),
    comboChoose: () => import('./ComboChoose.vue')
  },
  data() {
    return {
      rules: dictionaryRules,
      form: {},
      params: {},
      checked: 0  ,
      dataSource: {},
      formModal: {},
      loading: false,
      isCustom: false,
      customSource: null,
      currentSource: null,
      materialsSelectModalVisible: false
    }
  },
  computed: {
    getOption() {
      return this.dataSource.options?.filter(item => item.value !== 3)
    },
    getCurrentCombo() {
      if (this.currentSource) {
        return this.currentSource?.package_name || this.currentSource[0]?.goods_name
      } else {
        return ''
      }
    }
  },
  methods: {
    onChange(e) {
      this.checked = e.target.value
      this.form.name = e.target.value
      this.currentSource = null
      console.log(this.form, '🚀 ~ EditDictionary onChange line: 73 -----')
    },
    getCombos(combo) {
      this.currentSource = JSON.parse(JSON.stringify(combo))
    },
    getProductions(prods) {
      let arr = JSON.parse(JSON.stringify(prods))
      arr = this.deWeight(arr)
      this.form.production = arr
      if (this.isCustom) {
        this.customSource = arr
      } else {
        this.currentSource = arr
      }
    },
    // 数组去重
    deWeight(list) {
      return list.reduce((unique, o) => {
        // 去除id相同的对象
        if(!unique.some(obj => obj.id === o.id)) {
          unique.push(o)
        }
        return unique
      },[])
    },
    openProdModal(type) {
      this.materialsSelectModalVisible = true
      this.form.warehouse = 1
      // 设置单选
      this.$refs.material.setRadio()
      // 定制品
      this.isCustom = type === true
      const list = type ? this.customSource : this.currentSource
      // 赋值选中的selectedKey和selectedRows
      if (list) {
        const keys = list.reduce((unique, o) => {
          if (o.id) {
            unique.push(o.id)
          }
          return unique
        }, [])
        this.$refs.material.setSelectedRowKeys(keys, list)
      } else {
        this.$refs.material.setSelectedRowKeys([], [])
      }
    },
    getList(id) {
      dictionaryDetail({ id }).then(data => {
        this.dataSource = data
        data.options.forEach(item => {
          item.value = item.id
          item.label = item.name
          delete item.id
          delete item.name
        })
        if (data?.to_type) {
          this.checked = data.to_type
        }
        if (data?.to_type === 4 && data?.to_number) {
          this.form.amount = Number(data?.to_number)
        }
        if (data?.type_value) {
          this.currentSource = [{
            goods_name: data?.type_value?.name || data?.type_value?.original_name,
            goods: data?.type_value?.id || data?.type_value?.original_id
          }]
          if (data?.type_value?.current_name) {
            this.customSource = [{
              goods_name: data?.type_value?.current_name,
              goods: data?.type_value?.current_id
            }]
          }
        }
      }).finally(() => {
        this.loading = false
      })
    },
    editModal(id) {
      this.formModal = {
        open: true,
        title: '编辑字典',
        type: 'edit',
      }
      this.loading = true
      this.getList(id)
    },
    onConfirm() {
      this.$refs.formRef.validate(error => {
        if (!error) {
          return false
        }
        this.loading = true
        switch (this.formModal.type) {
          case 'edit':
            this.edit()
            break
          default:
            break
        }
      })
    },
    // TODO 字典修改 API
    edit() {
      let data = {}
      data.id = this.dataSource.id
      data.to_type = this.checked
      const errorMsg = this.handleValidate(this.checked)
      if (errorMsg) {
        message.error(errorMsg)
        this.loading = false
        return
      }
      switch (this.checked) {
        case 1:
          data.to_number = this.currentSource[0].goods
          break
        case 2:
          data.to_number = this.currentSource.id
          break
        case 4:
          data.to_number = this.form.amount
          break
        case 5:
          data.original = this.currentSource[0].goods
          data.current = this.customSource[0].goods
          break
        default:
          break
      }
      console.log(data, '🚀 ~ 修改')
      updateDictionary(data).then(res => {
        this.$message.success('修改成功')
        this.closeModal()
        this.$emit('ok')
      }).finally(() => {
        this.loading = false
      })
    },
    handleValidate(type) {
      if (type === 1 || type === 5) {
        if (!this.currentSource) {
          return '请选择原产品'
        }
      }
      if (type === 2) {
        if (!this.currentSource) {
          return '请选择套餐'
        }
      }
      if (type === 4) {
        if (!this.form.amount) {
          return '请选择金额类型'
        }
      }
      if (type === 5) {
        if (!this.customSource) {
          return '请选择定制品'
        }
      }
      return null
    },
    closeModal() {
      this.formModal.open = false
      this.params = {}
      this.checked = 0
      this.customSource = null
      this.currentSource = null
      this.$refs.formRef.resetFields()
    },
  }
}
</script>

<style lang="less" scoped>
.combo-grid {
  display: flex;
  flex-wrap: wrap;
  &-item {
    &:last-child {
      .ant-divider {
        border: 1px solid #333;
        visibility: hidden !important;
      }
    }
  }
}
.combo-list {
  display: flex;
  align-items: center;
}
/deep/ .ant-form-item-control {
  height: 40px;
  display: flex;
  align-items: center;
}
</style>
