import request from "@/utils/request";

// 获取模板 及 模板详情
export function templateDetail(params) {
  return request({ url: `/approval/template/`, method: "get", params });
}

// 获取模板选项类型
export function optionType(params) {
  return request({ url: `/approval/template/options_type/`, method: "get", params });
}

// 编辑模板
export function updateTemplate(data, id) {
  return request({ url: `/approval/template/${id}/`, method: 'patch', data })
}

// 删除模板
export function delTemplate(id) {
  return request({ url: `/approval/template/${id}/`, method: 'delete' })
}

// 删除模板下产品
export function delTemplatetree(data) {
  return request({ url: `/approval/templatetree/node_delete/`, method: 'delete', data })
}

// 获取字典
export function templatetree(params) {
  return request({ url: `/approval/templatetree/`, method: "get", params });
}

// 字典详情
export function dictionaryDetail(params) {
  return request({ url: `/approval/templatetree/node_info/`, method: "get", params });
}

// 编辑字典
export function updateDictionary(data) {
  return request({ url: `/approval/templatetree/node_update/`, method: "patch", data });
}
