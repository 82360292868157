<template>
  <div>
    <a-card title="产品字典定义">
      <a-row :gutter="24">
        <a-col :span="3">
          <a-input v-model="searchForm.template__title" placeholder="审批模板" allowClear />
        </a-col>
        <a-col :span="3">
          <a-input v-model="searchForm.parent_name" placeholder="标题名称" allowClear />
        </a-col>
        <a-col :span="3">
          <a-input v-model="searchForm.title" placeholder="选项名称" allowClear />
        </a-col>
        <a-col :span="3">
          <a-select
            v-model="searchForm.to_type_id"
            placeholder="对应类型"
            allowClear
            :style="{ width: '100%' }"
          >
            <a-select-option v-for="item in productionTypes" :key="item.value" :value="item.value">
              {{item.label}}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="3">
          <a-input v-model="searchForm.type_value" :disabled="!searchForm.to_type_id" placeholder="对应产品" allowClear />
        </a-col>
        <a-col :span="3">
          <a-select
              v-model="searchForm.is_to_type"
              placeholder="解析状态"
              allowClear
              :style="{ width: '100%' }"
          >
            <a-select-option v-for="item in parseState" :key="item.value" :value="item.value">
              {{item.label}}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="3">
          <a-space>
            <a-button type="primary" @click="init()">查询</a-button>
            <a-button @click="onReset">重置</a-button>
          </a-space>
        </a-col>
      </a-row>
      <a-table
        :rowKey="record => record.id"
        :columns="columns"
        :dataSource="dataSet"
        :loading="loading"
        :pagination="pagination"
        @change="tableChange"
        @expandedRowsChange="onExpandedRowsChange"
        :expanded-row-keys="expandedRowKeys"
        style="margin-top: 15px;"
      >
        <template slot="action" slot-scope="text, record">
          <a v-if="isChildren(record)" @click="$refs.EditDictionary.editModal(record.id)">编辑</a>
          <a v-else @click="$refs.EditTemplate.editModal(record.template)">编辑</a>
          <a-divider type="vertical" />
          <a-popconfirm title="确定删除吗?" @confirm="onDelete(record)">
            <a>删除</a>
          </a-popconfirm>
        </template>
        <template slot="is_config" slot-scope="text, record">
          <span v-if="text || record.to_type">
            <a-badge status="processing" />
            <span style="color: #1890ff;">解析成功</span>
          </span>
          <span v-else>
            <a-badge status="error" />
            <span style="color: #f5222d;">解析失败</span>
          </span>
        </template>
      </a-table>
    </a-card>
    <EditTemplate ref="EditTemplate" @ok="init()" />
    <EditDictionary ref="EditDictionary" @ok="init()" />
  </div>
</template>

<script>
import { parseState, columns } from '@/utils/maps/productDictionary'
import { templateDetail, templatetree, delTemplate, delTemplatetree, optionType } from '@/api/productionDictionary'

export default {
  name: 'productDictionary',
  components: {
    EditTemplate: () => import('./EditTemplate.vue'),
    EditDictionary: () => import('./EditDictionary.vue')
  },
  data() {
    return {
      searchForm: {},
      loading: false,
      pagination: {},
      dataSet: [],
      expandedRowKeys: [],
      innerData: [],
      productionTypes: [],
      parseState,
      columns,
      columnsInner
    };
  },
  mounted() {
    this.init()
    this.getOptionsType()
  },
  methods: {
    getOptionsType() {
      optionType().then(data => {
        const res = data.map(item => {
          const label = item.name
          const value = item.id
          return { label, value }
        })
        this.productionTypes = res.filter(item => item.value !== 3)
      })
    },
    isChildren(record) {
      if (record?.parent_name || record?.title || record?.to_type_name || record?.type_value) {
        return true
      }
      return false
    },
    onExpandedRowsChange(keys) {
      console.log(keys, '🚀 ~ productDictionary onExpandedRowsChange line: 120 -----')
      // this.expandedRowKeys = keys
      let arrset = null
      if (keys.length) {
        const index = keys[keys.length - 1]
        arrset = this.dataSet.filter(item => item.id === index)[0]
        if (!arrset) {
          return;
        }
        if (!arrset?.approvaltype) {
          this.expandedRowKeys = []
          this.$message.error('请先对该模板进行设置')
        } else {
          this.expandedRowKeys = keys
        }
      } else {
        this.expandedRowKeys = []
      }

    },
    init(page, page_size) {
      this.loading = true;
      this.searchForm = {
        ...this.searchForm,
        page: page || 1,
        page_size: page_size || 10
      }
      if (!this.pagination.pageSize || !page || !page_size) {
        this.pagination = { current: 1, total: 0, pageSize: 10 }
      }
      this.getList()
    },
    getList() {
      // goodsInformationList(this.searchForm).then(data => {
      templateDetail(this.searchForm).then(data => {
        const { results = {}, count = 0 } = data
        this.dataSet = results
        this.pagination.total = count
        setTimeout(() => {
          const params = JSON.parse(JSON.stringify(this.searchForm))
          delete params.page
          delete params.page_size
          if (Object.keys(params).length) {
            const arrset = this.dataSet[0]
            console.log(arrset, '🚀 ~ productDictionary onExpandedRowsChange line: 136 -----')
            if (arrset?.approvaltype) {
              this.expandedRowKeys = [arrset.id]
            }
          }
        }, 300)
      }).finally(() => {
        this.loading = false
      })
    },
    tableChange(pagination) {
      this.pagination = { ...this.pagination, ...pagination }
      this.init(pagination.current, pagination.pageSize)
    },
    onReset() {
      this.searchForm = {}
      this.expandedRowKeys = []
      this.pagination = { ...this.pagination, current: 1, pageSize: 10 }
      this.init()
    },
    async onDelete(record) {
      let result = null
      if (this.isChildren(record)) {
        const id = record.id
        result = await delTemplatetree({ id }).finally(() => {
          this.loading = false
        })
      } else {
        result = await delTemplate(record.id).finally(() => {
          this.loading = false
        })
      }
      if (result) {
        this.$message.success('删除成功')
        this.onReset()
      }
    }
  },
}
</script>

<style scoped>
</style>
